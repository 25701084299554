import {sectionEnum} from "./sectionEnum";
import NavigationComponent from "./components/navigationComponent";
import TextSection from "./components/textSection";
import Footer from "./components/footer";

const Impressum = () => {
  return <>
    <NavigationComponent enums={[
      sectionEnum.WILLKOMMEN,
      sectionEnum.VERANSTALTUNGEN,
      sectionEnum.LEISTUNGEN,
      sectionEnum.TERMINE,
      sectionEnum.UEBER
    ]}/>

    <TextSection enum={sectionEnum.IMPRESSUM}/>

    <Footer/>
  </>


}

export default Impressum;
