import {MapContainer, Marker, TileLayer} from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const PraxisMap = () => {
  const coordinates = [53.48774, 10.21303]

  return <div className="map-container">
    <MapContainer center={coordinates} zoom={16} style={{height: "200px", width: "95%"}}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={coordinates}/>
    </MapContainer>
  </div>
}
export default PraxisMap
