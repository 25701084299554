import React from "react";
import "./navigationComponent.css";
import {useMediaQuery} from "react-responsive";
import NavigationBarDesktop from "./navigationBarDesktop";
import NavigationBarMobile from "./navigationBarMobile";

const NavigationComponent = (props) => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 767px)'})

  return <>
    {
      isTabletOrMobile ? <NavigationBarMobile enums={props.enums}/> :
        <NavigationBarDesktop enums={props.enums}/>
    }
  </>
};

export default NavigationComponent;
