import './hero.css'

const Hero = () => {
  return <div className="hero">
    <h1>
      Privatpraxis für Psychiatrie und Psychotherapie<br/>
      Maliha Malik
    </h1>
    <div>Fachärztin für Psychiatrie und Psychotherapie</div>
  </div>
}

export default Hero;
