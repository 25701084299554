import './contactLink.css';

const ContactLink = (props) => {
  let link = "";
  let value = ""

  switch (props.type) {
    case "phone":
      value = "04152 - 9080110"
      link = `tel:${value.replace(" - ", "")}`;
      break;
    case "email":
      value = "malik@psychiater-bergedorf.de"
      link = `mailto:${value}`;
      break;
  }

  return <>
    <a href={link} className="contact-link">
      {value}
    </a>
  </>
}

export default ContactLink;
