import PraxisMap from "./components/praxisMap";
import ContactLink from "./components/contactLink";

export const sectionEnum = {
  UEBER: {
    'name': 'Über Uns',
    'id': 'ueber',
    'html': <div className="text">
      <div className="image-text-container">
        <div className="portrait-image"><img src="/maliha-malik.jpeg" alt="" id="portrait"/></div>
        <div className="left-text">
          Ich bin in Geesthacht geboren und habe pakistanische Wurzeln.
          Ich habe Medizin an der Universität des Saarlandes und an der Universität
          zu Lübeck studiert. Es war bereits im Studium mein Ziel Psychiaterin zu
          werden. Ich habe direkt nach dem Studium meine Weiterbildung als Ärztin
          in der Psychiatrie in Lüneburg und in Geesthacht weitergeführt.
          Ich arbeite derzeit an meiner Promotion zum Thema <i>Lebensqualität
          und Lebenslage psychiatrischer Patientinnen und Patienten mit pakistanischem
          Migrationshintergrund im Raum Hamburg</i>.
        </div>
      </div>
    </div>
  },
  WILLKOMMEN: {
    'name': 'Willkommen',
    'id': 'willkommen',
    'html': <div className="text">
      Ich bin Maliha Malik. Diese Privatpraxis habe ich gegründet, um besser und
      individueller auf die Belange meiner Patientinnen und Patienten eingehen zu können.
      Seit Februar 2022 arbeite ich nun in meiner eigenen Privatpraxis in Bergedorf. <br/>
      Sie können mich telefonisch über die <b><ContactLink type="phone"/></b> oder
      per E-Mail unter <b><ContactLink type="email"/></b> erreichen.
      Die Praxis befindet sich am <b>Sachsentor 26, 21029 Hamburg-Bergedorf</b> im 3. Stock.<br/><br/>
      <PraxisMap/>
    </div>
  },
  LEISTUNGEN: {
    'name': 'Leistungen',
    'id': 'leistungen',
    'html': <div className="text">
      Die Behandlung erfolgt bei mir individuell auf die Patienten zugeschnitten.
      Meinerseits besteht vor allem ein Interesse an transkultureller Psychiatrie,
      dabei geht es mir besonders um das Thema Migration und Psychiatrie. <br/>
      <h3>Meine Behandlung umfasst:</h3>
      <ul>
        <li>Burnout & Stress</li>
        <li>Affektive Störungen (Depression)</li>
        <li>Trauma</li>
        <li>Psychose</li>
        <li>Persönlichkeitsstörungen</li>
      </ul>

      <h3>Das multimodale Therapieangebot umfasst dabei:</h3>
      <ul>
        <li>Ausführliche Erklärung und Beratung in Bezug auf die Erkrankung und die Behandlungsmöglichkeiten</li>
        <li>Stützende psychiatrische Gespräche</li>
        <li>Krisenintervention</li>
        <li>Medikamentöse Behandlung</li>
        <li>Tiefenpsychologisch fundierte Psychotherapie</li>
        <li>Entspannungsverfahren</li>
      </ul>
    </div>
  },
  TERMINE: {
    'name': 'Termine',
    'id': 'termine',
    'html': <div className="text">
      Termine können mit mir direkt vereinbart werden über die <b><ContactLink type="phone"/></b> Ich
      richte mich flexibel nach meinen Patientinnen und Patienten. Außerdem können Sie eine
      E-Mail schreiben an <b><ContactLink type="email"/></b>.
    </div>
  },
  IMPRESSUM: {
    'name': 'Impressum',
    'id': '',
    'html': <div className="text">
      <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
      <p>Privatpraxis für Psychatrie und Psychotherapie Maliha Malik<br/>
        Sachsentor 26<br/>
        3. Stock<br/>
        21029 Hamburg-Bergedorf</p>

      <h3>Kontakt</h3>
      <p><b>Telefon</b>: <ContactLink type="phone"/><br/>
        <b>E-Mail</b>: <ContactLink type="email"/></p>
    </div>
  },
  VERANSTALTUNGEN: {
    'name': 'Veranstaltungen',
    'id': 'veranstaltungen',
    'html': <div className="text">
      <p>Ab Dezember 2023 biete ich eine Gruppe für islamische Frauen an.</p>
      <div className="next-event">
        <div className="event-headline">Nächste Veranstaltung</div>
        <div><b>Datum:</b><span>&nbsp;&nbsp;16. Dezember 2023</span></div>
        <div><b>Uhrzeit:</b><span>&nbsp;&nbsp;14:00 Uhr</span></div>
        <div><b>Ort:</b><span>&nbsp;&nbsp;Hamburg</span></div>
        <div><b>Kosten:</b><span>&nbsp;&nbsp;50,00€</span></div>
      </div>
      <div>Schreiben Sie bitte eine E-Mail an <ContactLink type="email"/>, um sich anzumelden. Die genaue Adresse wird Ihnen nach erfolgreicher Anmeldung mitgeteilt. Ich freue mich auf Ihre Teilnahme!</div>
    </div>
  }
};
